import {
  routes,
  get,
  post,
  put,
  deleteApiCall,
  nonExpGet,
  nonExpPost,
  pbiPost,
  nonExpPut,
} from "./index";
export const getClient = (user) => {
  return get(`${"exp/me/"}`, { user });
};

export const searchClientUsers = (searchValue, user) => {
  let urlParams = "";
  if (searchValue !== undefined && searchValue !== "") {
    urlParams = "?name=" + searchValue;
  }
  return get(`${"exp/client/user/search" + urlParams}`, { user });
};
export const getClientUser = (searchValue, user) => {
  let urlParams = "";
  if (searchValue !== undefined && searchValue !== "") {
    urlParams = "?id=" + searchValue;
  }
  return get(`${"exp/client/user/search" + urlParams}`, { user });
};

export const createClientUser = (body, user) => {
  return post(`${"/exp/client/user"}`, { body, user });
};

export const searchClientWorkspaces = (searchValue, user) => {
  let urlParams = "";
  if (searchValue !== undefined && searchValue !== "") {
    urlParams = "?name=" + searchValue;
  }
  return get(`${"exp/client/workspaces/search" + urlParams}`, { user });
};

export const searchPbiResources = (searchValue, workspaceId, user) => {
  let urlParams = "";
  if (searchValue !== undefined && searchValue !== "") {
    urlParams = "&name=" + searchValue;
  }
  return get(
    `${
      "exp/client/user/pbiResources/search?workspaceId=" +
      workspaceId +
      urlParams
    }`,
    { user }
  );
};
export const searchAllPbiResources = (searchValue, user) => {
  let urlParams = "";
  if (searchValue !== undefined && searchValue !== "") {
    urlParams = "?name=" + searchValue;
  }
  return get(
    `${
      "exp/client/user/pbiResources/search"  +
      urlParams
    }`,
    { user }
  );
};

export const getPbiResources = (resourceId, user) => {
  return get(`${"exp/client/user/pbiResource/" + resourceId}`, { user });
};
export const getNotificationActivity = (notificationId, user) => {
  return get(`${"exp/notifications/" + notificationId + "/activity"}`, {
    user,
  });
};
export const getResourceEmbedToken = (resourceId, user) => {
  return get(`${"exp/client/pbiResources/" + resourceId + "/embedToken"}`, {
    user,
  });
};

export const getEmbedToken = (body, user) => {
  return post(`${"exp/client/pbiResources/embedToken"}`, { body, user });
};

export const pbiResourcesRefresh = (user, body = {}) => {
  return post(`${"exp/client/pbiResources/refresh"}`, { body, user });
};

export const searchClientSecurityGroups = (searchValue, user) => {
  let urlParams = "";
  if (searchValue !== undefined && searchValue !== "") {
    urlParams = "?name=" + searchValue;
  }
  return get(`${"exp/client/user/securityGroups" + urlParams}`, { user });
};
export const searchClientSecurityGroupsList = (searchValue, user) => {
  let urlParams = "";
  if (searchValue !== undefined && searchValue !== "") {
    urlParams = "?name=" + searchValue;
  }
  return get(`${"exp/client/securityGroup/search" + urlParams}`, { user });
};

export const createClientSecurityGroup = (body, user) => {
  return post(`${"exp/client/securityGroup"}`, { body, user });
};

export const createClientUserSecurityGroupLink = (body, user) => {
  return post(`${"exp/client/user/securityGroup"}`, {
    body,
    user,
  });
};
export const createClientWorkspaceSecurityGroupLink = (body, user) => {
  return post(`${"exp/client/workspace/securityGroup"}`, {
    body,
    user,
  });
};

export const searchCreatedNotifications = (searchValue, user) => {
  let urlParams = "";
  if (searchValue !== undefined && searchValue !== "") {
    urlParams = "?name=" + searchValue;
  }
  return get(`${"exp/notifications/createdBy" + urlParams}`, { user });
};

export const createClientNotification = (body, user) => {
  let contentType =
    "multipart/form-data; boundary=<calculated when request is sent>";
  return post(`${"exp/notifications/file"}`, {
    body,
    user,
    contentType,
  });
};

export const updateWorkspaceData = (workspaceId, body, user) => {
  return put(`${"exp/client/workspace/" + workspaceId}`, { body, user });
};
export const updatePbIResourceData = (resourceId, body, user) => {
  return put(`${"exp/client/pbiresource/" + resourceId}`, { body, user });
};
export const updateClientUser = (userId, body, user) => {
  return put(`${"exp/client/user/" + userId}`, { body, user });
};

export const searchClientSubscriptions = (searchValue, user) => {
  let urlParams = "";

  if (searchValue !== undefined && searchValue !== "") {
    urlParams += "?id=" + searchValue;
  }
  console.log(urlParams);
  return get(`${"exp/client/subscription/search" + urlParams}`, { user });
};
export const getClientSubscriptions = (id, user) => {
  let urlParams = "";

  if (id !== undefined && id !== "") {
    urlParams += "?id=" + id;
  }

  return get(`${"exp/client/subscription/search" + urlParams}`, { user });
};
export const createClientSubscription = (body, user) => {
  return post(`${"exp/client/subscription"}`, {
    body,
    user,
  });
};

export const searchPbiReports = (workspaceId, user) => {
  return get(
    `${
      "exp/client/user/pbiResources/search?type=Report&workspaceId=" +
      workspaceId
    }`,
    { user }
  );
};
export const searchSecurityGroupUsers = (securityGroupId, user) => {
  return get(`${"exp/client/securityGroup/" + securityGroupId + "/users"}`, { user });
};
export const searchWorkspaceSecurityGroup = (user) => {
  return get(`${"exp/client/workspace/securityGroup/search"}`, { user });
};
export const deleteSecurityGroup = (securityGroupId, user) => {
  return deleteApiCall(`${"exp/client/securityGroup/"+ securityGroupId}`, { user });
};

export const updatePbiConfig = (body, user) => {
  return put(`${"exp/client/pbiConfig"}`, { body, user });
};
export const uploadClientLogo = (body, user) => {
  return post(`${"exp/client/logo"}`, { body, user });
};