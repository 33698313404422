import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import MainLayout from "../layout/MainLayout";

import {  
  uploadClientLogo,
} from "../../../services/clientAdmin";
import LoaderComponent from "../../../components/clientAdmin/LoaderComponent";
import AlertMessage from "../../../components/clientAdmin/AlertMessage";
import { useNavigate, useParams, Link } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import {
  setWebsiteName,
  setActivityLoader,
  setUserData,
  activeMenu,
  setActiveMenu,
} from "../../../store/features/generalSlice";
import { Formik, Form, Field, ErrorMessage, Checkbox } from "formik";

function ClientLogo({ instance }) {
  const [data, setData] = useState([]);
  const { clientId } = useParams();
  const [accessToken, setAccessToken] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [error, setError] = useState(null);
  const [showLoader, setShowLoader] = useState(true);
  const [searchValue, setSearchValue] = useState();
  const userData = useSelector((state) => state.general.userData);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  dispatch(setActiveMenu({ activeMenu: "client info" }));

  async function uploadLogoImage(params) {
    let user = false;
    setShowLoader(true);
    if (!userData) {
      let pbUser = await getUserData();
      user = {
        token: pbUser.accessToken,
      };
    } else {
      user = {
        token: userData.accessToken,
      };
    }

    uploadClientLogo(params, user)
      .then((res) => {
        let succesMessage = "Client logo uploaded successfully";
        localStorage.setItem("alertMessage", succesMessage);
        navigate("/client/client-info");
      })
      .catch((error) => {
        console.log("error", error);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          setError(error.response.data.message);
          setShowLoader(false);
        } else if (error.request) {
          // The request was made but no response was received
          setError("No response received from the server.");
          setShowLoader(false);
        } else {
          // Something happened in setting up the request that triggered an error
          setError("An error occurred while processing the request.");
          setShowLoader(false);
        }
      });
  }
  async function getUserData() {
    let pbUser = false;

    if (!userData) {
      pbUser = await localStorage.getItem("pbUser");

      if (pbUser) {
        pbUser = JSON.parse(pbUser);
        setAccessToken(pbUser.accessToken);
        dispatch(setUserData({ userData: pbUser }));
      }
    }
    return pbUser;
  }

  useEffect(() => {
    let ignore = false;
    getUserData();
    setShowLoader(false);
    return () => {
      ignore = true;
    };
  }, []);

  const [file, setFile] = useState(null);
  const [banner, setBanner] = useState(null);
  const [favicon, setFavicon] = useState(null);

  const handleChange = (e) => {
    setFile(e.target.files[0]);
  };
  const handleBannerChange = (e) => {
    setBanner(e.target.files[0]);
  };
  const handleFaviconChange = (e) => {
    setFavicon(e.target.files[0]);
  };

  return (
    <MsalProvider instance={instance}>
      <MainLayout>
        {showLoader && <LoaderComponent />}
        {!showLoader && (
          <div className="inner client-creation-pg clearfix">
            <div className="title-top-wraper2">
              <h1>Client Logo Upload</h1>
            </div>
            {alertMessage && (
              <AlertMessage alertMessage={alertMessage} alertType={"success"} />
            )}
            {error && <AlertMessage alertMessage={error} alertType={"error"} />}
            <div className="main-bg-wraper--2">
              <Formik
                initialValues={{
                  file: null,
                  banner: null,
                  favicon: null,
                  metaTitle: null,
                  metaDescription: null,

                }}
                onSubmit={async (values) => {
                  
                  const base64Image = await new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = () => resolve(reader.result);
                    reader.onerror = reject;
                    reader.readAsDataURL(file);
                  });
                  const base64BannerImage = await new Promise(
                    (resolve, reject) => {
                      const reader = new FileReader();
                      reader.onload = () => resolve(reader.result);
                      reader.onerror = reject;
                      reader.readAsDataURL(banner);
                    }
                  );
                  const base64FaviconImage = await new Promise(
                    (resolve, reject) => {
                      const reader = new FileReader();
                      reader.onload = () => resolve(reader.result);
                      reader.onerror = reject;
                      reader.readAsDataURL(favicon);
                    }
                  );
                  await new Promise((r) => setTimeout(r, 500));
                  const formData = new FormData();
                  
                  formData.append("file", base64Image.split(",")[1]);
                  formData.append("fileName", "logo.png");
                  formData.append("bannerName", "bannerName.png");
                  formData.append("banner", base64BannerImage.split(",")[1]);
                  formData.append("faviconName", "FaviconName.png");
                  formData.append("favicon", base64FaviconImage.split(",")[1]);
                  formData.append("metaTitle", values.metaTitle);
                  formData.append("metaDescription", values.metaDescription);
                  

                  uploadLogoImage(formData);
                }}
              >
                {({ values, setFieldValue }) => (
                  <Form>
                    <div className="details-list-wraper">
                      <div className="details-list-inner">
                        <div className="row justify-content-center">
                          <div className="col-xl-8 col-lg-10">
                            <div className="form-style-1">
                              <div className="mb-4">
                                <div className="row align-items-center">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    Logo Image
                                  </label>
                                  <div className="col-md-9">
                                    <Field
                                      type="file"
                                      name="file"
                                      className="form-control"
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="row align-items-center">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    Banner Image
                                  </label>
                                  <div className="col-md-9">
                                    <Field
                                      type="file"
                                      name="banner"
                                      className="form-control"
                                      onChange={handleBannerChange}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="row align-items-center">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    Favicon 
                                  </label>
                                  <div className="col-md-9">
                                    <Field
                                      type="file"
                                      name="favicon"
                                      className="form-control"
                                      onChange={handleFaviconChange}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="row align-items-center">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    Meta Title
                                  </label>
                                  <div className="col-md-9">
                                    <Field
                                      type="text"
                                      name="metaTitle"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="row align-items-center">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    Meta Description
                                  </label>
                                  <div className="col-md-9">
                                    <Field
                                      type="text"
                                      name="metaDescription"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="btm-btns-row">
                      <div className="row justify-content-center">
                        <div className="col-xl-8 col-lg-10">
                          <div className="btngroup2">
                            <Link
                              className="button-new1 grey"
                              to={`/client/client-info`}
                            >
                              Cancel
                            </Link>
                            <button className="grad-btn-1">Submit</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        )}
      </MainLayout>
    </MsalProvider>
  );
}

export default ClientLogo;
