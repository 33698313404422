import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import MainLayout from "../layout/MainLayout";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  searchClientSecurityGroups,
  searchClientUsers,
  createClientUserSecurityGroupLink,
  searchClients,
} from "../../../services";
import { useNavigate, useParams, Link } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import LoaderComponent from "../../../components/admin/LoaderComponent";
import AlertMessage from "../../../components/admin/AlertMessage";
import FormErrorMessage from "../../../components/clientAdmin/FormErrorMessage";
import {
  setWebsiteName,
  setActivityLoader,
  setUserData,
  activeMenu,
  setActiveMenu,
} from "../../../store/features/generalSlice";

function ClientGroupMap({ instance }) {
  const [data, setData] = useState(false);
  const [clientUsers, setClientUsers] = useState(false);
  const [clientGroups, setClientGroups] = useState(false);
  const [clientList, setClientList] = useState(false);
  const [accessToken, setAccessToken] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const { clientId, resourceId } = useParams();
  
  const [formValues, setFormValues] = useState(false);


  const [error, setError] = useState(null);
  const [showLoader, setShowLoader] = useState(true);
  const [searchValue, setSearchValue] = useState();
  const userData = useSelector((state) => state.general.userData);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  dispatch(setActiveMenu({ activeMenu: "clients" }));
  async function getUserData() {
    let pbUser = false;

    if (!userData) {
      pbUser = await localStorage.getItem("pbUser");

      if (pbUser) {
        pbUser = JSON.parse(pbUser);
        setAccessToken(pbUser.accessToken);
        //dispatch(setUserData({ userData: pbUser }));
      }
    }
    return pbUser;
  }
  const mapClientGroup = (params) => {
    setShowLoader(true);
    const user = {
      token: accessToken,
    };
    createClientUserSecurityGroupLink(clientId, params, user)
      .then((res) => {
        let succesMessage = "Client user group maping created successfully";
        localStorage.setItem("alertMessage", succesMessage);
        navigate("/client-list");
      })
      .catch((error) => {
        console.log("error", error);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          setError(error.response.data.message);
        } else if (error.request) {
          // The request was made but no response was received
          setError("No response received from the server.");
        } else {
          // Something happened in setting up the request that triggered an error
          setError("An error occurred while processing the request.");
        }
        setShowLoader(false);
      });
  };
  async function getClientGroups(otherClientId = null) {
    let user = false;
    
    if (!userData) {
      let pbUser = await getUserData();
      user = {
        token: pbUser.accessToken,
      };
    } else {
      user = {
        token: userData.accessToken,
      };
    }
    let fetchClientId = clientId;
    if (otherClientId !== null) {
      fetchClientId = otherClientId;
    }
    const response = await searchClientSecurityGroups(fetchClientId, user);

    const results = [];
    // Store results in the results array
    response.data.forEach((value) => {
      results.push({
        key: value.name,
        value: value.id,
      });
    });
    // Update the options state
    setClientGroups(results);
  }
  async function getClientUsers(otherClientId = null) {
    let user = false;
   
    if (!userData) {
      let pbUser = await getUserData();
      user = {
        token: pbUser.accessToken,
      };
    } else {
      user = {
        token: userData.accessToken,
      };
    }
    let fetchClientId = clientId;
    if (otherClientId !== null) {
      fetchClientId = otherClientId;
    }
    const response = await searchClientUsers(fetchClientId, user);

    const results = [];
    // Store results in the results array
    response.data.forEach((value) => {
      results.push({
        key: value.name,
        value: value.id,
      });
    });
    // Update the options state
    setClientUsers(results);
  }
  const getClientList = async () => {
    let user = false;
    
    if (!userData) {
      let pbUser = await getUserData();
      user = {
        token: pbUser.accessToken,
      };
    } else {
      user = {
        token: userData.accessToken,
      };
    }
    const response = await searchClients("", user);
    const results = [];
    // Store results in the results array
    response.data.forEach((value) => {
      results.push({
        key: value.name,
        value: value.id,
      });
    });
    // Update the options state
    setClientList(results);
  };

  const ValidationSchema = Yup.object().shape({
    clientIdForGroup: Yup.string().required("'Client' must not be empty"),
    clientIdForUser: Yup.string().required("'Client' must not be empty"),
    securityGroupId: Yup.string().required(
      "'Security Group Id' must not be empty."
    ),
    userId: Yup.string().required("'User' must not be empty."),
  });

  useEffect(() => {
    let userData = localStorage.getItem("pbUser");
    setAlertMessage(localStorage.getItem("alertMessage"));
    localStorage.setItem("alertMessage", "");
    let accessToken = "";
    if (userData) {
      userData = JSON.parse(userData);
      accessToken = userData.accessToken;
    } else {
      userData = null;
    }
    const user = {
      token: accessToken,
    };
    setAccessToken(accessToken);
    let ignore = false;
    if (accessToken != "") {
      getUserData();
      getClientUsers();
      getClientGroups();
      getClientList();
    }
    setShowLoader(false);
    return () => {
      ignore = true;
    };
  }, []);
  return (
    <MsalProvider instance={instance}>
      <MainLayout>
        {showLoader && <LoaderComponent />}
        {!showLoader && (
          <div className="inner client-creation-pg clearfix">
            <div className="title-top-wraper2">
              <h1>Client User Group Map</h1>
            </div>
            {alertMessage && (
              <AlertMessage alertMessage={alertMessage} alertType={"success"} />
            )}
            {error && <AlertMessage alertMessage={error} alertType={"error"} />}
            <div className="main-bg-wraper--2">
              <Formik
                initialValues={{
                  clientIdForGroup: formValues?.clientIdForGroup
                    ? formValues.clientIdForGroup
                    : "",
                  clientIdForUser: formValues?.clientIdForUser
                    ? formValues.clientIdForUser
                    : "",
                  securityGroupId: formValues?.securityGroupId
                    ? formValues.securityGroupId
                    : "",
                  userId: formValues?.userId ? formValues.userId : "",
                  status: true,
                }}
                validationSchema={ValidationSchema}
                onSubmit={async (values) => {
                  await new Promise((r) => setTimeout(r, 500));
                  setFormValues(values);
                  mapClientGroup(JSON.stringify(values, null, 2));
                }}
              >
                {({ errors, touched, values, setFieldValue }) => (
                  <Form>
                    <div className="details-list-wraper">
                      <div className="details-list-inner">
                        <div className="row justify-content-center">
                          <div className="col-xl-8 col-lg-10">
                            <div className="form-style-1">
                              <div className="mb-4">
                                <div className="row align-items-center blank-field">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    Client
                                  </label>
                                  <div className="col-md-9">
                                    {clientList && (
                                      <Field
                                        name="clientIdForGroup"
                                        className="form-control"
                                        as="select"
                                        onChange={(e) => {
                                          const selectedValue = e.target.value;
                                          setFieldValue(
                                            "clientIdForGroup",
                                            selectedValue
                                          );
                                          getClientGroups(e.target.value);
                                        }}
                                      >
                                        <option key="" value="">
                                          Select Client
                                        </option>
                                        {clientList.map((option) => {
                                          return (
                                            <option
                                              key={option.value}
                                              value={option.value}
                                            >
                                              {option.key}
                                            </option>
                                          );
                                        })}
                                      </Field>
                                    )}
                                    {errors.clientIdForGroup &&
                                    touched.clientIdForGroup ? (
                                      <FormErrorMessage
                                        message={errors.clientIdForGroup}
                                      />
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="row align-items-center blank-field">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    Security Group
                                  </label>
                                  <div className="col-md-9">
                                    {clientGroups && (
                                      <Field
                                        name="securityGroupId"
                                        className="form-control"
                                        as="select"
                                      >
                                        <option key="" value="">
                                          Select Security Group
                                        </option>
                                        {clientGroups.map((option) => {
                                          return (
                                            <option
                                              key={option.value}
                                              value={option.value}
                                            >
                                              {option.key}
                                            </option>
                                          );
                                        })}
                                      </Field>
                                    )}
                                    {errors.securityGroupId &&
                                    touched.securityGroupId ? (
                                      <FormErrorMessage
                                        message={errors.securityGroupId}
                                      />
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="row align-items-center blank-field">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    Client
                                  </label>
                                  <div className="col-md-9">
                                    {clientList && (
                                      <Field
                                        name="clientIdForUser"
                                        className="form-control"
                                        as="select"
                                        onChange={(e) => {
                                          const selectedValue = e.target.value;
                                          setFieldValue(
                                            "clientIdForUser",
                                            selectedValue
                                          );
                                          getClientUsers(selectedValue);
                                        }}
                                      >
                                        <option key="" value="">
                                          Select Client
                                        </option>
                                        {clientList.map((option) => {
                                          return (
                                            <option
                                              key={option.value}
                                              value={option.value}
                                            >
                                              {option.key}
                                            </option>
                                          );
                                        })}
                                      </Field>
                                    )}
                                    {errors.clientIdForUser &&
                                    touched.clientIdForUser ? (
                                      <FormErrorMessage
                                        message={errors.clientIdForUser}
                                      />
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="row align-items-center blank-field">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    Users
                                  </label>
                                  <div className="col-md-9">
                                    {clientUsers && (
                                      <Field
                                        name="userId"
                                        className="form-control"
                                        as="select"
                                      >
                                        <option key="" value="">
                                          Select Users
                                        </option>
                                        {clientUsers.map((option) => {
                                          return (
                                            <option
                                              key={option.value}
                                              value={option.value}
                                            >
                                              {option.key}
                                            </option>
                                          );
                                        })}
                                      </Field>
                                    )}
                                    {errors.userId && touched.userId ? (
                                      <FormErrorMessage
                                        message={errors.userId}
                                      />
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="btm-btns-row">
                      <div className="row justify-content-center">
                        <div className="col-xl-8 col-lg-10">
                          <div className="btngroup2">
                            <Link
                              className="button-new1 grey"
                              to={`/client-security-groups/${clientId}`}
                            >
                              Cancel
                            </Link>
                            <button className="grad-btn-1">Submit</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        )}
      </MainLayout>
    </MsalProvider>
  );
}

export default ClientGroupMap;
