import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import MainLayout from "../layout/MainLayout";
import moment from "moment";

import {
  getMyNotifications,
  notificationActivity,
  notificationAllActivity,
} from "../../../services/user";
import {
    getClient
} from "../../../services/clientAdmin";
import LoaderComponent from "../../../components/clientAdmin/LoaderComponent";
import ImagePopup from "../../../components/user/ImagePopup";
import { useNavigate, useParams, Link } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import {
  setWebsiteName,
  setActivityLoader,
  setUserData,
  activeMenu,
  setActiveMenu,
} from "../../../store/features/generalSlice";
import Moment from "react-moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "moment-timezone";
import DownloadIcon from "../../../assets/theme/images/download-icon.svg";
import FileIcon from "../../../assets/theme/images/file-icon.svg";
import AttachmentImage from "../../../assets/theme/images/attachment-image-1.jpg";
import SearchBlack from "../../../assets/theme/images/search-black.png";

import MailOpenGreen from "../../../assets/theme/images/mail-open-green.png";
import MailOpen from "../../../assets/theme/images/mail-open.png";
import MailActive from "../../../assets/theme/images/mail-active.png";
import DateIcon from "../../../assets/theme/images/date-icon.png";
import Form from "react-bootstrap/Form";
import {
  Edit2,
  TopClose,
  NotiIcon,
  View,
  TrashTwo,
  BurgerMenu
} from "../../../assets/theme/images/icons";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";

export default function ClientInfo({ instance }) {
  const imageExtns = ["png", "jpg", "jpeg", "gif", "PNG", "JPEG", "JPG", "GIF"];
  const docExtns = ["pdf", "doc", "PDF", "DOC"];
  const [data, setData] = useState([]);
  const [accessToken, setAccessToken] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [skip, setSkip] = useState(null);
  const [prevSkip, setPrevSkip] = useState(null);
  const [status, setStatus] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchValue, setSearchValue] = useState(null);
  const [error, setError] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [imageUrl, setImageUrl] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [continuationToken, setContinuationToken] = useState();
  const [unreadCount, setUnreadCount] = useState();
  const userData = useSelector((state) => state.general.userData);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  dispatch(setActiveMenu({ activeMenu: "client info" }));
  async function getUserData() {
    let pbUser = false;

    if (!userData) {
      pbUser = await localStorage.getItem("pbUser");

      if (pbUser) {
        pbUser = JSON.parse(pbUser);
        setAccessToken(pbUser.accessToken);
        dispatch(setUserData({ userData: pbUser }));
      }
    }
    return pbUser;
  }

  async function startFetching(skipVal = null) {
    const prevSkipval = skipVal - 20;
    let user = false;
    setShowLoader(true);
    if (!userData) {
      let pbUser = await getUserData();
      user = {
        token: pbUser.accessToken,
      };
    } else {
      user = {
        token: userData.accessToken,
      };
    }
    setAlertMessage(localStorage.getItem("alertMessage"));
    localStorage.setItem("alertMessage", "");
    let ignore = false;
    const formattedStartDate = startDate
      ? moment(startDate).format("YYYY-MM-DD")
      : null;
    const formattedEndDate = endDate
      ? moment(endDate).format("YYYY-MM-DD")
      : null;
    const response = await getClient(
      user,
    ).catch((error) => {
      if (error.code && error.code == "ERR_NETWORK") {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        //setError(error.response.data.message);
        ignore = true;
        setShowLoader(false);
        navigate("/client/error-page");
      } else if (error.request) {
        ignore = true;
        // The request was made but no response was received
        setError("No response received from the server.");
        setShowLoader(false);
      } else {
        ignore = true;
        // Something happened in setting up the request that triggered an error
        setError("An error occurred while processing the request.");
        setShowLoader(false);
      }
    });

    if (!ignore) {
      setData(response.data);      
      setShowLoader(false);
    }
  }

  const clearSearch = () => {
    setStartDate(null);
    setEndDate(null);
    setStatus(null);
    startFetching();
  };
  

  useEffect(() => {
    let ignore = false;
    getUserData();
    startFetching();
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <MsalProvider instance={instance}>
      <MainLayout>
        {showLoader && <LoaderComponent />}
        {!showLoader && (
          <div className="inner client-creation-pg clearfix">
            <div className="title-top-wraper2 flex-wrap">
              <h1>Client Info</h1>
            </div>

            <div className="main-bg-wraper--2">
              <div className="details-list-wraper">
                {alertMessage && (
                  <div className="alert alert-success" role="alert">
                    <div className="alert-message">{alertMessage}</div>
                  </div>
                )}
                {error && (
                  <div className="alert alert-error" role="alert">
                    <div className="alert-message">{error}</div>
                  </div>
                )}
                <div className="notifications-wrap">
                  <div className="notification-filter">
                    <div className="search">
                      {/* <img src={SearchBlack} alt="" />
                      <input
                        placeholder="Search"
                        type="text"
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                      /> */}
                    </div>

                    <div className="right-filter"></div>
                  </div>

                  <div className="read-unread"></div>

                  <div className="listing-table-wraper">
                    <div className="table-list-items">
                      <div className="table-responsive min-h-400">
                        <table className="table table-borderless align-middle table-style-new1">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>ID</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td> {data.name} </td>
                              <td> {data.clientId} </td>
                              <td>
                                <ul className="action-icons-list">
                                  <li>
                                    <DropdownButton
                                      id="dropdown-basic-button"
                                      className="with-dropdown"
                                      title={<BurgerMenu />}
                                    >
                                      <Dropdown.Item
                                        href={`/client/client-pbi-config/`}
                                      >
                                        Pbi Config
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        href={`/client/client-logo`}
                                      >
                                        Client Logo
                                      </Dropdown.Item>
                                    </DropdownButton>
                                  </li>
                                </ul>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        
      </MainLayout>
    </MsalProvider>
  );
}
