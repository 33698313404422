import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useSelector, useDispatch } from "react-redux";

import BurggerMenu from "../../../assets/theme/images/burgger-menu.png";
import SideBarMenuLogo from "../../../assets/theme/images/logo-small.png";

import {
  Dashboard,
  Clients,
  ClientGroups,
  Reports,
  QnA,
  NotiIcon,
  Info,

  ClientUsers,
  SecurityGroups,
  Subscriptions,
  ManageNotifications,
  ActivityLog,
  DisplayMode


} from "../../../assets/theme/images/icons";

import {
  setWebsiteName,
  setActivityLoader,
  setUserData,
  setMenuOpenClose,
} from "../../../store/features/generalSlice";

function BasicNavTab() {
  const dispatch = useDispatch();
  const general = useSelector((state) => state.general);
  
  function menuToggle() {
    let temp = general?.menuOpenClose === false ? true : false;
    dispatch(setMenuOpenClose({ menuOpenClose: temp }));
    console.log("menuOpenClose", general?.menuOpenClose);
  }
  return (
    <Navbar expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <div className="logo-menu">
          <button className="open-close" onClick={() => menuToggle()}>
            <img src={BurggerMenu} alt="" />
          </button>
          <a href="/client/dashboard" className="s-logo" >
            <img src={general?.logoImage ? general?.logoImage :SideBarMenuLogo} alt="" style={{maxWidth:"100%"}} />
          </a>
        </div>

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/client/dashboard" className={general?.activeMenu == 'dashboard' ? "active" : ""  }>
              <div className="menu-cover">
                <Dashboard /> Dashboard
              </div>
            </Nav.Link>
            {/* <Nav.Link href="/client/workspaces" className={general?.activeMenu == 'workspaces' ? "active" : ""  }>
              <div className="menu-cover">
                <Clients /> Workspaces
              </div>
            </Nav.Link>             */}

            <Nav.Link href="/client/users-list" className={general?.activeMenu == 'client user' ? "active" : ""  }>
              <div className="menu-cover">
                <ClientUsers /> Client Users
              </div>
            </Nav.Link>
            <Nav.Link href="/client/security-groups" className={general?.activeMenu == 'security groups' ? "active" : ""  }>
              <div className="menu-cover">
                <SecurityGroups /> Security Groups
              </div>
            </Nav.Link>
            <Nav.Link href="/client/security-groups-workspaces" className={general?.activeMenu == 'security groups workspaces' ? "active" : ""  }>
              <div className="menu-cover">
                <SecurityGroups /> Security Groups Workspaces
              </div>
            </Nav.Link>
            <Nav.Link href="/client/subscriptions" className={general?.activeMenu == 'subscriptions' ? "active" : ""  }>
              <div className="menu-cover">
                <Subscriptions /> Subscriptions
              </div>
            </Nav.Link>
            <Nav.Link href="/client/created-notifications" className={general?.activeMenu == 'manage notifications' ? "active" : ""  }>
              <div className="menu-cover">
                <ManageNotifications /> Manage Notifications
              </div>
            </Nav.Link>
            <Nav.Link href="/client/my-notifications" className={general?.activeMenu == 'my notifications' ? "active" : ""  }>
              <div className="menu-cover">
                <NotiIcon /> Notifications
              </div>
            </Nav.Link>
            <Nav.Link href="/client/user-activities" className={general?.activeMenu == 'user activities' ? "active" : ""  }>
              <div className="menu-cover">
                <ActivityLog /> Activity Log 
              </div>
            </Nav.Link>
            <Nav.Link href="/client/active-devices" className={general?.activeMenu == 'active devices' ? "active" : ""  }>
              <div className="menu-cover">
                <DisplayMode /> Active Devices
              </div>
            </Nav.Link>
            <Nav.Link href="/client/client-info" className={general?.activeMenu == 'client info' ? "active" : ""  }>
              <div className="menu-cover">
                <DisplayMode /> Client Info
              </div>
            </Nav.Link>
            
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default BasicNavTab;
