import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import BurggerMenu from "../../assets/theme/images/burgger-menu.png";
import BurggerMenuWhite from "../../assets/theme/images/burgger-menu-white.png";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import Favicon from "react-favicon";

import {
  setBurggerMenuOpenClose,
  setUserData,
  ClearAll,
} from "../../store/features/generalSlice";
import {
  getMyNotifications,
  createActivityLog,
  deleteDeviceToken,
} from "../../services/user";

import { setIdToken } from "../../store/features/userSlice";

import { NotiIcon } from "../../assets/theme/images/icons";
import UserAvatar from "../../assets/theme/images/user-avatar.png";
import arrowUser from "../../assets/theme/images/arrow-1.png";

import {
  useMsal,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  MsalProvider,
  useIsAuthenticated,
} from "@azure/msal-react";

function Navbar() {
  const [showProfileMenu, setShowProfileMenu] = useState("");
  const userData = useSelector((state) => state.general.userData);
  const [data, setData] = useState([]);
  const [actionCompleted, setActionCompleted] = useState(false);
  const navigate = useNavigate();
  const { instance } = useMsal();
  const dispatch = useDispatch();
  const general = useSelector((state) => state.general);
  const handleLogout = async () => {
    localStorage.removeItem("msalUser");
    localStorage.removeItem("pbUser");
    dispatch(setUserData({ userData: false }));
    dispatch(setIdToken({ idToken: false }));
    dispatch(ClearAll({ idToken: false }));
    await addActivityLog("Logout successfully done");
  };
  function burggerMenuToggle() {
    let temp = general?.burggerMenuOpenClose === false ? true : false;
    dispatch(setBurggerMenuOpenClose({ burggerMenuOpenClose: temp }));
    console.log("burggerMenuOpenClose", general?.burggerMenuOpenClose);
  }
  function handleShowProfileMenu() {
    let temp = !showProfileMenu;
    setShowProfileMenu(temp);
  }

  async function getUserData() {
    let pbUser = false;

    if (!userData) {
      pbUser = await localStorage.getItem("pbUser");

      if (pbUser) {
        pbUser = JSON.parse(pbUser);
        dispatch(setUserData({ userData: pbUser }));
      }
    }
    return pbUser;
  }

  async function startFetching() {
    let user = false;
    if (!userData) {
      let pbUser = await getUserData();
      user = {
        token: pbUser ? pbUser.accessToken : "",
      };
    } else {
      user = {
        token: userData ? userData.accessToken : "",
      };
    }
    try {
      const response = await getMyNotifications("", user);
      setData(response.data);
    } catch (error) {
      if (error.code && error.code === "ERR_NETWORK") {
        // console.error("Unauthorized: Check your authentication credentials.");
        navigate("/");
      } else {
        //console.error("An error occurred:", error.message);
        navigate("/");
      }
      throw error;
    }
  }
  async function addActivityLog(log) {
    let user = false;
    if (!userData) {
      let pbUser = await getUserData();
      user = {
        token: pbUser.accessToken,
      };
    } else {
      user = {
        token: userData.accessToken,
      };
    }

    const message = log;
    const extra = log;
    let formData = {
      activity: log,
      message: message,
      extra: extra,
    };

    createActivityLog(formData, user)
      .then((res) => {
        console.log("general?.powerBIEmbedLayoutType");
        removeDeviceToken();
      })
      .catch((e) => {
        if (e?.response) {
          let errorMessage = e.response.message;
        }
      });
  }
  async function removeDeviceToken(log) {
    let user = false;
    if (!userData) {
      let pbUser = await getUserData();
      user = {
        token: pbUser.accessToken,
      };
    } else {
      user = {
        token: userData.accessToken,
      };
    }
    let deviceToken = localStorage.getItem("deviceToken");
    let paramsData = {
      deviceToken: deviceToken == null ? 'null' : deviceToken,
      deviceType: "Mobile",
      action: "Delete",
    };

    deleteDeviceToken(paramsData, user)
      .then((res) => {
        console.log("success");
        instance.logout();
        navigate("/");
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  useEffect(() => {
    getUserData();
    startFetching();
  }, []);
  return (
    <>
      <Helmet>
        <title>{general?.metaTitle ? general?.metaTitle : ""}</title>
        <meta
          name="description"
          content={general?.metaDescription ? general?.metaDescription : ""}
        />
        <meta
          name="keywords"
          content={general?.metaKeywords ? general?.metaKeywords : ""}
        />
        <meta name="author" content="Your Name" />
        <meta
          property="og:title"
          content={general?.metaTitle ? general?.metaTitle : ""}
        />
        <meta
          property="og:description"
          content={general?.metaDescription ? general?.metaDescription : ""}
        />
        <meta
          property="og:image"
          content={general?.metaImage ? general?.metaImage : ""}
        />
        <meta
          property="og:url"
          content={general?.metaUrl ? general?.metaUrl : ""}
        />
        <meta
          name="twitter:title"
          content={general?.metaTitle ? general?.metaTitle : ""}
        />
        <meta
          name="twitter:description"
          content={general?.metaDescription ? general?.metaDescription : ""}
        />
        <meta
          name="twitter:image"
          content={general?.metaImage ? general?.metaImage : ""}
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Favicon
        url={general?.favicon ? general?.favicon : ""}
        alertCount="10"
        alertFillColor="white"
        alertTextColor="black"
      />
      <div className="top">
        <div className="cell-one">
          {/* <div className="search">
          <img src="images/search.png" alt="" />
          <input placeholder="Search Projects" type="text" />
        </div> */}
          <button
            className="open-close mobBurggerButton"
            onClick={() => burggerMenuToggle()}
          >
            <img src={BurggerMenuWhite} alt="" />
          </button>
          <ul className="menu">
            <li className={general?.activeMenu == "dashboard" ? "active" : ""}>
              <a href="/user/dashboard">Dashboard</a>
            </li>
          </ul>

          <select name="" id="" className="nice-select">
            <option value="">Dashboard</option>
          </select>
        </div>
        <div className="cell-two">
          {/* <div class="main-loader fullSize">
    <div class="loader"></div>
</div> */}
          <a href="/user/my-notifications" className="noti">
            <div className="count">{data?.unReadCount}</div>
            <NotiIcon />
          </a>
          <div className="loginUser">
            <img
              src={UserAvatar}
              alt=""
              onClick={() => handleShowProfileMenu()}
            />
            <span onClick={() => handleShowProfileMenu()}>
              {general?.userData.email}
            </span>
            <img
              className="arrow"
              src={arrowUser}
              alt=""
              onClick={() => handleShowProfileMenu()}
            />

            {/* <ul className="dropdown-menu show"> */}
            <ul
              className={
                showProfileMenu ? "dropdown-menu show" : "dropdown-menu"
              }
            >
              <li className="name">{general?.userData.email}</li>
              {/* <li>
                <a class="dropdown-item" href="#">
                  Profile
                </a>
              </li>

              <li>
                <a class="dropdown-item" href="#">
                  Settings
                </a>
              </li> */}
              <li>
                <a class="dropdown-item" onClick={handleLogout}>
                  Logout
                </a>
              </li>
            </ul>
          </div>
          {/* <div className="dropdown">
          <a
            className="btn btn-secondary dropdown-toggle"
            href="#"
            role="button"
            id="dropdownMenuLink"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img src={UserAvatar} alt="" /> John Due
          </a>

          <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
            <li>
              <a className="dropdown-item" href="#">
                Profile
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="#">
                Settings
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="#">
                Logout
              </a>
            </li>
          </ul>
        </div> */}
        </div>
      </div>
    </>
  );
}

export default Navbar;
