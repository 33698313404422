import { useState, useEffect, useId } from "react";
import {
  useMsal,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { loginRequest } from "../utils/authConfig"; // Import your MSAL configuration
import { Helmet } from "react-helmet";
import Favicon from "react-favicon";

import AidiLogo from "../assets/theme/images/powered-logo.png";
import Logo from "../assets/theme/images/logo.png";
import LeftLogo from "../assets/theme/images/left-logo.jpg";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import {
  browserName,
  browserVersion,
  osName,
  osVersion,
} from "react-device-detect";

import {
  manageDeviceToken,
  createActivityLog,
  getNameandLogo,
} from "../services/user";

import { getWebsiteNameLogo, login, getEmbedToken } from "../services";
import { useSelector, useDispatch } from "react-redux";
import {
  setWebsiteName,
  setActivityLoader,
  setUserData,
  setLogoImage,
  setBannerImage,
  setMetaTitle,
  setMetaDescription,
  setMetaKeywords,
  setMetaImage,
  setMetaUrl,
  setFavicon,
  setPrimaryColor,
  setSecondaryColor,
  ClearAll
} from "../store/features/generalSlice";
import { setIdToken, setIdTokenExp } from "../store/features/userSlice";
import LoaderComponent from "./user/LoaderComponent";

const MsalLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [error, setError] = useState(null);
  const [email, setEmail] = useState(null);
  const [showLoader, setShowLoader] = useState(true);

  const uniqueID = uuidv4();
  //const activityLoader = useSelector((state) => state.general.activityLoader);
  const userData = useSelector((state) => state.general.userData);
  const userSlice = useSelector((state) => state.user);
  const general = useSelector((state) => state.general);
  const { instance, inProgress, accounts } = useMsal();
  console.log(accounts);
  const request = {
    scopes: ["openid"],
    account: accounts[0],
  };
  instance
    .acquireTokenSilent(request)
    .then((response) => {
      setIdToken(response.idToken);
    })
    .catch((error) => {
      console.log(error);
      // acquireTokenSilent can fail for a number of reasons, fallback to interaction
      // if (error instanceof InteractionRequiredAuthError) {
      //   instance.acquireTokenPopup(request).then((response) => {
      //     setIdToken(response.idToken);
      //   });
      // }
    });
  const { isAuthenticated } = useMsal();
  const handleLogin = (e) => {
    e.preventDefault();
    loginRequest.loginHint = email;
    instance.loginPopup(loginRequest).catch((error) => {
      console.error("Login error: ", error);
      setError("Login error: ", error);
    });
  };

  const handleLogout = () => {
    instance.logout();
  };

  async function getIdToken() {
    let retn;
    const request = {
      scopes: ["openid"],
      account: accounts[0],
    };

    await instance
      .acquireTokenSilent(request)
      .then((response) => {
        retn = response.idToken;
        dispatch(setIdToken({ idToken: response.idToken }));
      })
      .catch((error) => {
        // acquireTokenSilent can fail for a number of reasons, fallback to interaction
        // if (error instanceof InteractionRequiredAuthError) {
        //   instance.acquireTokenPopup(request).then((response) => {
        //     setIdToken(response.idToken);
        //   });
        // }
      });
    return retn;
  }

  async function loginFunction() {
    const account = accounts[0];
    localStorage.setItem("msalUser", JSON.stringify(account));
    const currentTime = Date.now();
    console.log(currentTime);
    // if(currentTime) {
    //dispatch(setIdToken({ idToken: false }));

    let msalToken =
      !userSlice.idToken && userSlice.idTokenExp < currentTime
        ? await getIdToken()
        : userSlice.idToken;
    // Accessing user information
    const params = {
      idToken: msalToken,
      email: account.username,
      domain: window.location.hostname,
      tenantId: account.tenantId,
      clientId: account.idTokenClaims.aud,
      groups: account.idTokenClaims.groups,
    };
    await login(params)
      .then((res) => {
        const user = res.data;
        dispatch(setUserData({ userData: user }));
        localStorage.setItem("pbUser", JSON.stringify(user));
        localStorage.setItem("sessionId", user.userId + "::" + uniqueID);
        setIsLoggedIn(true);
        let deviceToken = localStorage.getItem("deviceToken");
        const userAgent = navigator.userAgent;
        let deviceType;
        if (
          /Mobile|Android|iOS|iPhone|iPad|iPod|Windows Phone/i.test(userAgent)
        ) {
          deviceType = "Mobile";
        } else {
          deviceType = "Desktop";
        }
        let paramsData = {
          deviceToken: deviceToken == null ? 'null' : deviceToken,
          deviceType: deviceType,
          browser: `${browserName} ${browserVersion}`,
          os: `${osName} ${osVersion}`,
          action: "Add",
        };
        const userToken = {
          token: user.accessToken,
        };

        manageDeviceToken(paramsData, userToken)
          .then((res) => {
            console.log("success");
          })
          .catch((error) => {
            console.log("error", error);
          });
        addActivityLog("Logged in successfully", userToken);
      })
      .catch((e) => {
        if (e?.response) {
          let errorMessage = e.response.data.message;
          setError("Login error: " + errorMessage);
          setIsLoggedIn(false);
          localStorage.removeItem("msalUser");
          localStorage.removeItem("pbUser");
          dispatch(setUserData({ userData: false }));
          dispatch(setIdToken({ idToken: false }));
          dispatch(ClearAll({ idToken: false }));

          instance.logout();
        } else {
          setError("Login error: ");
        }
      });
  }
  async function addActivityLog(log, userToken) {
    const message =
      log +
      " : " +
      `${browserName} ${browserVersion} on ${osName} ${osVersion}`;
    const extra = log;
    let formData = {
      activity: log,
      message: message,
      extra: extra,
    };

    createActivityLog(formData, userToken)
      .then((res) => {
        console.log("general?.powerBIEmbedLayoutType");
      })
      .catch((e) => {
        if (e?.response) {
          let errorMessage = e.response.message;
        }
      });
  }
  async function getDomainData(hostingDomain) {
    getNameandLogo(hostingDomain)
      .then((res) => {
        
        res.data.logo ? dispatch(setLogoImage({ logoImage: res.data.logo })) : dispatch(setLogoImage({ logoImage: Logo }));
        res.data.banner ? dispatch(setBannerImage({ bannerImage: res.data.banner })) : dispatch(setBannerImage({ bannerImage: LeftLogo }));
        dispatch(setMetaTitle({ metaTitle: res.data.metaTitle }));
        dispatch(
          setMetaDescription({ metaDescription: res.data.metaDescription })
        );
        dispatch(setMetaKeywords({ metaKeywords: res.data.metaDescription }));
        dispatch(setMetaImage({ metaImage: res.data.logo }));
        dispatch(setMetaUrl({ metaUrl: res.data.logo }));
        dispatch(setFavicon({ favicon: res.data.favicon }));
        res.data.primaryColor != "" &&
          dispatch(setPrimaryColor({ primaryColor: res.data.primaryColor }));
        res.data.secondaryColor != "" &&
          dispatch(
            setSecondaryColor({ secondaryColor: res.data.secondaryColor })
          );
        setShowLoader(false);
      })
      .catch((e) => {
        if (e?.response) {
          dispatch(setLogoImage({ logoImage: Logo }));
          dispatch(setBannerImage({ bannerImage: LeftLogo }));
          let errorMessage = e.response.message;
          console.log(errorMessage);
          setShowLoader(false);
        }
      });
  }

  useEffect(() => {
    if (accounts.length > 0) {
      if (!isLoggedIn) {
        loginFunction();
      }
      if (isLoggedIn) {
        if (userData.role === "SuperAdmin") {
          //navigate("/client/dashboard");
          navigate("/dashboard");
        } else if (userData.role === "ClientAdmin") {
          navigate("/dashboard");
          //navigate("/client/dashboard");
        } else if (userData.role === "User") {
          navigate("/user/dashboard");
        } else {
          navigate("/client/dashboard");
        }
      }
    }
    let hostname = window.location.hostname;

    console.log("hostname", hostname.split(".")[0]);
    getDomainData(hostname);
  }, [accounts, isLoggedIn]);

  return (
    <>
      {showLoader && <LoaderComponent />}
      {!showLoader && (
        <>
          <Helmet>
            <title>{general?.metaTitle ? general?.metaTitle : ""}</title>
            <meta
              name="description"
              content={general?.metaDescription ? general?.metaDescription : ""}
            />
            <meta
              name="keywords"
              content={general?.metaKeywords ? general?.metaKeywords : ""}
            />
            <meta name="author" content="Your Name" />
            <meta
              property="og:title"
              content={general?.metaTitle ? general?.metaTitle : ""}
            />
            <meta
              property="og:description"
              content={general?.metaDescription ? general?.metaDescription : ""}
            />
            <meta
              property="og:image"
              content={general?.metaImage ? general?.metaImage : ""}
            />
            <meta
              property="og:url"
              content={general?.metaUrl ? general?.metaUrl : ""}
            />
            <meta
              name="twitter:title"
              content={general?.metaTitle ? general?.metaTitle : ""}
            />
            <meta
              name="twitter:description"
              content={general?.metaDescription ? general?.metaDescription : ""}
            />
            <meta
              name="twitter:image"
              content={general?.metaImage ? general?.metaImage : ""}
            />
            <meta name="twitter:card" content="summary_large_image" />
          </Helmet>
          <Favicon
            url={general?.favicon ? general?.favicon : ""}
            alertCount="10"
            alertFillColor="white"
            alertTextColor="black"
          />

          <section className="login-section">
            <div className="container-fluid h-100 px-0">
              <div className="row g-0 h-100">
                <div className="col-xl-8 col-lg-7 col-md-6 h-100 d-none d-md-block">
                  <div className="logsec-left">
                    <img
                      src={general?.bannerImage ? general?.bannerImage : ""}
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-xl-4 col-lg-5 col-md-6 h-100">
                  <div className="logsec-right">
                    <div className="lsr-inner">
                      <div className="lsr-logo">
                        <img
                          src={general?.logoImage ? general?.logoImage : ""}
                          alt=""
                        />
                      </div>

                      <form className="form-style-1">
                        {error && (
                          <div className="alert alert-danger" role="alert">
                            <div className="alert-message">{error}</div>
                          </div>
                        )}
                        <div className="row">
                          <div className="col-md-12">
                            <div className="input-group mb-3">
                              <span className="input-group-text" id="">
                                <i className="fa-regular fa-envelope"></i>
                              </span>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Your Email ID"
                                email="email"
                                onChange={(e) => setEmail(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <AuthenticatedTemplate>
                              <button
                                onClick={handleLogout}
                                className="btn btn-style-1 w-100"
                              >
                                Logout
                              </button>
                            </AuthenticatedTemplate>
                            <UnauthenticatedTemplate>
                              <button
                                onClick={handleLogin}
                                className="btn btn-style-1 w-100"
                              >
                                Signin Using Microsoft
                              </button>
                            </UnauthenticatedTemplate>
                          </div>
                        </div>
                      </form>

                      <div className="poweredby">
                        <p>Powered by</p>
                        <img src={AidiLogo} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default MsalLogin;
