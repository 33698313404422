import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import MainLayout from "../layout/MainLayout";
import {
  createClientSecurityGroup,
  searchClientSecurityGroups,
  searchClientSecurityGroupsList,
  pbiResourcesRefresh,
} from "../../../services/clientAdmin";
import LoaderComponent from "../../../components/clientAdmin/LoaderComponent";
import AlertMessage from "../../../components/clientAdmin/AlertMessage";
import FormErrorMessage from "../../../components/clientAdmin/FormErrorMessage";
import { useNavigate, useParams, Link } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import {
  setWebsiteName,
  setActivityLoader,
  setUserData,
  activeMenu,
  setActiveMenu,
} from "../../../store/features/generalSlice";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

function ClientSecurityGroupUpdate({ instance }) {
  const [data, setData] = useState([]);
  const [accessToken, setAccessToken] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const { id } = useParams();
  const [error, setError] = useState(null);
  const [showLoader, setShowLoader] = useState(true);
  const [searchValue, setSearchValue] = useState();
  const userData = useSelector((state) => state.general.userData);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  dispatch(setActiveMenu({ activeMenu: "security groups" }));
  async function getUserData() {
    let pbUser = false;

    if (!userData) {
      pbUser = await localStorage.getItem("pbUser");

      if (pbUser) {
        pbUser = JSON.parse(pbUser);
        setAccessToken(pbUser.accessToken);
        dispatch(setUserData({ userData: pbUser }));
      }
    }
    return pbUser;
  }
  async function saveClientGroup(params) {
    setShowLoader(true);
    let user = false;
    setShowLoader(true);
    if (!userData) {
      let pbUser = await getUserData();
      user = {
        token: pbUser.accessToken,
      };
    } else {
      user = {
        token: userData.accessToken,
      };
    }
    createClientSecurityGroup(params, user)
      .then((res) => {
        let succesMessage = "Client user created successfully";
        localStorage.setItem("alertMessage", succesMessage);
        setShowLoader(false);
        navigate("/client/security-groups");
      })
      .catch((error) => {
        console.log("error", error);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          setError(error.response.data.message);
        } else if (error.request) {
          // The request was made but no response was received
          setError("No response received from the server.");
        } else {
          // Something happened in setting up the request that triggered an error
          setError("An error occurred while processing the request.");
        }
        setShowLoader(false);
      });
  }
  const ValidationSchema = Yup.object().shape({
    securityGroupId: Yup.string().required("Required"),
    name: Yup.string().required("Required"),
    roles: Yup.string().required("Required"),
  });

  async function startFetching() {
    let user = false;
    setShowLoader(true);
    if (!userData) {
      let pbUser = await getUserData();
      user = {
        token: pbUser.accessToken,
      };
    } else {
      user = {
        token: userData.accessToken,
      };
    }
    setAlertMessage(localStorage.getItem("alertMessage"));
    localStorage.setItem("alertMessage", "");
    let ignore = false;
    const response = await searchClientSecurityGroups(searchValue, user).catch(
      (error) => {
        if (error.code && error.code == "ERR_NETWORK") {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          //setError(error.response.data.message);
          ignore = true;
          setShowLoader(false);
        } else if (error.request) {
          ignore = true;
          // The request was made but no response was received
          setError("No response received from the server.");
          setShowLoader(false);
        } else {
          ignore = true;
          // Something happened in setting up the request that triggered an error
          setError("An error occurred while processing the request.");
          setShowLoader(false);
        }
      }
    );
    const userSgs = response.data;
    const responseData = await searchClientSecurityGroupsList(
      searchValue,
      user
    ).catch((error) => {
      if (error.code && error.code == "ERR_NETWORK") {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        //setError(error.response.data.message);
        ignore = true;
        setShowLoader(false);
      } else if (error.request) {
        ignore = true;
        // The request was made but no response was received
        setError("No response received from the server.");
        setShowLoader(false);
      } else {
        ignore = true;
        // Something happened in setting up the request that triggered an error
        setError("An error occurred while processing the request.");
        setShowLoader(false);
      }
    });
    const clientSgs = responseData.data;
    const combineSgs = (arr1, arr2) => {
      // Concatenate the two arrays
      const combined = [...arr1, ...arr2];

      // Remove duplicates based on unique 'id'
      const uniqueArray = combined.filter(
        (item, index, self) => index === self.findIndex((t) => t.id === item.id)
      );

      return uniqueArray;
    };

    if (!ignore) {
      const combined = combineSgs(userSgs, clientSgs);
      const tempData = await combined.filter((item) => {
        return Object.keys(item).some((key) =>
          key == "id" ? item[key].toLowerCase().includes(id)
            : ""
        );
      });
      
      setData(tempData[0]);      
      setShowLoader(false);
      console.log(tempData[0]);
    }
  }
  useEffect(() => {
    let ignore = false;
    getUserData();
    startFetching();
    
    return () => {
      ignore = true;
    };
  }, []);
  return (
    <MsalProvider instance={instance}>
      <MainLayout>
        {showLoader && <LoaderComponent />}
        {!showLoader && (
          <div className="inner client-creation-pg clearfix">
            <div className="title-top-wraper2">
              <h1>Client Security Group Create</h1>
            </div>
            {alertMessage && (
              <AlertMessage alertMessage={alertMessage} alertType={"success"} />
            )}
            {error && <AlertMessage alertMessage={error} alertType={"error"} />}

            <div className="main-bg-wraper--2">
              <Formik
                initialValues={data}
                validationSchema={ValidationSchema}
                onSubmit={async (values) => {
                  await new Promise((r) => setTimeout(r, 500));
                  saveClientGroup(JSON.stringify(values, null, 2));
                }}
              >
                {({ errors, touched, values, setFieldValue }) => (
                  <Form>
                    <div className="details-list-wraper">
                      <div className="details-list-inner">
                        <div className="row justify-content-center">
                          <div className="col-xl-8 col-lg-10">
                            <div className="form-style-1">
                              <div className="mb-4">
                                <div className="row align-items-center blank-field">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    Name
                                  </label>
                                  <div className="col-md-9">
                                    <Field
                                      type="text"
                                      name="name"
                                      className="form-control"
                                    />
                                    {errors.name && touched.name ? (
                                      <FormErrorMessage message={errors.name} />
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="row align-items-center blank-field">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    Security Group ID
                                  </label>
                                  <div className="col-md-9">
                                    <Field
                                      type="text"
                                      name="securityGroupId"
                                      className="form-control"
                                    />
                                    {errors.securityGroupId &&
                                    touched.securityGroupId ? (
                                      <FormErrorMessage
                                        message={errors.securityGroupId}
                                      />
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="row align-items-center blank-field">
                                  <label
                                    for=""
                                    className="col-md-3 col-form-label"
                                  >
                                    Roles
                                  </label>
                                  <div className="col-md-9">
                                    <Field
                                      type="text"
                                      name="roles"
                                      className="form-control"
                                    />
                                    {errors.roles && touched.roles ? (
                                      <FormErrorMessage
                                        message={errors.roles}
                                      />
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="btm-btns-row">
                      <div className="row justify-content-center">
                        <div className="col-xl-8 col-lg-10">
                          <div className="btngroup2">
                            <Link
                              className="button-new1 grey"
                              to={`/client/security-groups`}
                            >
                              Cancel
                            </Link>
                            <button className="grad-btn-1">Submit</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        )}
      </MainLayout>
    </MsalProvider>
  );
}

export default ClientSecurityGroupUpdate;
