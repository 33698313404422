import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useSelector, useDispatch } from "react-redux";

import BurggerMenu from "../../../assets/theme/images/burgger-menu.png";
import SideBarMenuLogo from "../../../assets/theme/images/logo-small.png";

import {
  Dashboard,
  Clients,
  ClientGroups,
  Reports,
  QnA,
  DisplayMode,
} from "../../../assets/theme/images/icons";

import {
  setWebsiteName,
  setActivityLoader,
  setUserData,
  setMenuOpenClose,
} from "../../../store/features/generalSlice";

function BasicNavTab() {
  const dispatch = useDispatch();
  const general = useSelector((state) => state.general);

  function menuToggle() {
    let temp = general?.menuOpenClose === false ? true : false;
    dispatch(setMenuOpenClose({ menuOpenClose: temp }));
    console.log("menuOpenClose", general?.menuOpenClose);
  }
  return (
    <Navbar expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <div className="logo-menu">
          <button className="open-close" onClick={() => menuToggle()}>
            <img src={BurggerMenu} alt="" />
          </button>
          <a href="#" className="s-logo">
            <img src={SideBarMenuLogo} alt="" style={{ maxWidth: "100%" }} />
          </a>
        </div>

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link
              href="/dashboard"
              className={general?.activeMenu == "dashboard" ? "active" : ""}
            >
              <div className="menu-cover">
                <Dashboard /> Dashboard
              </div>
            </Nav.Link>
            <Nav.Link
              href="/client-list"
              className={general?.activeMenu == "clients" ? "active" : ""}
            >
              <div className="menu-cover">
                <Clients /> Clients
              </div>
            </Nav.Link>
            {general.userData.role === "SuperAdmin" && (
              <Nav.Link
                href="/client-admin-map"
                className={general?.activeMenu == "clients" ? "active" : ""}
              >
                <div className="menu-cover">
                  <Clients /> Client Admin Map
                </div>
              </Nav.Link>
            )}

            <Nav.Link
              href="activity-log"
              className={general?.activeMenu == "activity log" ? "active" : ""}
            >
              <div className="menu-cover">
                <Reports /> Activity Log
              </div>
            </Nav.Link>
            <Nav.Link
              href="/client-notifications"
              className={
                general?.activeMenu == "manage notifications" ? "active" : ""
              }
            >
              <div className="menu-cover">
                <QnA /> Manage Notifications
              </div>
            </Nav.Link>
            <Nav.Link
              href="/active-devices"
              className={
                general?.activeMenu == "active devices" ? "active" : ""
              }
            >
              <div className="menu-cover">
                <DisplayMode /> Active Devices
              </div>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default BasicNavTab;
